<template>
  <div class="content">
    <div class="menu">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待支付</el-menu-item>
        <el-menu-item index="2">打单中</el-menu-item>
        <el-menu-item index="3">已出单</el-menu-item>
<!--        <el-menu-item index="4">异常</el-menu-item>-->
        <el-menu-item index="5">取消</el-menu-item>
        <el-menu-item index="6">发货完成</el-menu-item>
        <span class="el-menu-item" style="color: red">1.待支付订单超过【15分钟】未支付将自动取消; 2.如果支付成功系统自动取消您的订单，可能是收货地疫情原因，请您尝试选择邮政发货</span>
      </el-menu>
    </div>
    <div class="table">
      <el-table
        :data="orderData.data"
        style="width: 100%"
        :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}"
        :row-style="{height: '80px'}"
        >
        <el-table-column
          label="序号"
          align="center"
          width="50"
          >
          <template slot-scope="scope"><span style="justify-content: center">{{scope.$index+(orderData.current_page - 1) * orderData.per_page + 1}}</span></template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          >
          <template slot-scope="scope"><span style="justify-content: center">{{timeConversion(scope.row.createtime)}}</span></template>
        </el-table-column>
        <el-table-column
          prop="order_from"
          align="center"
          label="店铺名称"
          >
        </el-table-column>
        <el-table-column
          label="商品信息"
          align="center"
          >
          <template slot-scope="scope">
            <div class="product-info">
              <img :src="scope.row.goods_images">
              <span>{{scope.row.goods_name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="包裹总数"
          >
          <template slot-scope="scope">
            <div @click="eye(scope.row)" class="child-orders" style="justify-content: center">
              {{scope.row.package_num}}笔<i class="el-icon-view"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="包裹统计">
          <template slot-scope="scope">
                <span>待出单：<span style="color: red">{{scope.row.wait_package_count}}</span></span><br/>
                <span>已出单：<span style="color: red">{{scope.row.package_count}}</span></span><br/>
                <span>已退款：<span style="color: red">{{scope.row.return_package_count}}</span></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_price"
          align="center"
          label="付款金额"
          >
        </el-table-column>
        <el-table-column
          label="订单状态"
          align="center"
          >
          <template slot-scope="scope">
            <!-- 待支付 -->
            <div v-if="scope.row.status === '1'" class="to-be-paid state" style="justify-content: center">
              <span></span>待支付
            </div>
            <!-- 打单中 -->
            <div v-if="scope.row.status === '2'" class="in-order state" style="justify-content: center">
              <span></span>打单中
            </div>
            <!-- 已出单 -->
            <div v-if="scope.row.status === '3'" class="order-issued state" style="justify-content: center">
              <span></span>已出单
            </div>
            <!-- 异常 -->
            <div v-if="scope.row.status === '4'" class="abnormal state" style="justify-content: center">
              <span></span>异常
            </div>
            <!-- 取消 -->
            <div v-if="scope.row.status === '5'" class="cancel state" style="justify-content: center">
              <span></span>取消
            </div>
            <!-- 取消 -->
            <div v-if="scope.row.status === '6'" class="handle state" style="justify-content: center">
              <span></span>发货完成
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="平台"
          align="center"
          >
          <template slot-scope="scope">
            <div style="justify-content: center">
              <div v-if="scope.row.platform === '0'">淘宝/天猫</div>
              <div v-if="scope.row.platform === '1'">阿里巴巴</div>
              <div v-if="scope.row.platform === '3'">拼多多</div>
              <div v-if="scope.row.platform === '2'">京东</div>
              <div v-if="scope.row.platform === '4'">抖音</div>
              <div v-if="scope.row.platform === '6'">快手</div>
              <div v-if="scope.row.platform === '5'">其他</div>
              <div v-if="scope.row.platform === '7'">淘工厂</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_tips"
          align="center"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="操作"
          width="120"
          fixed="right"
          >
          <template slot-scope="scope" >
            <div style="justify-content: center;flex-flow: column;cursor: pointer;color: hsl(210deg 100% 63%)">
              <div @click="payThis(scope.row)" v-if="scope.row.status === '1'">支付订单</div>
              <div @click="remove(scope.row.id)" v-if="scope.row.status === '1' || scope.row.status === '4'" class="remove">取消订单</div>
              <div @click="sendGoods(scope.row.id)" v-if="scope.row.status === '6' || scope.row.status === '3'" >一键发货</div>
              <div v-if="scope.row.status !== '1'" @click="exportOrder(scope.row.id)">
                下载订单
              </div>
              <div @click="eye(scope.row)" >查看包裹</div>
              <div v-if="scope.row.status == '5' && scope.row.order_type < 2" @click="copyAddress(scope.row)">
                重新发货
              </div>
<!--              <div @click="pushThird(scope.row.id)" v-if="(scope.row.status === '6' || scope.row.status === '5') && scope.row.is_notice == 1 && scope.row.third_return_url != ''" style="color: #dd6161;">重新推送</div>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <child-table :showDialog="showDialog" @closeDialog="closeDialog" :packageData="packageData" @updatePackageData="updatePackageData" :id="id" :showDownload="showDownload"/>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="orderData.per_page"
          layout="prev, pager, next"
          :current-page="orderData.current_page"
          :total="orderData.total">
        </el-pagination>
      </div>
    </div>
    <!-- 付款Dialog -->
    <pay-dialog :fromList="fromList" :dialogShow="dialogShow" @toOrderList="toOrderList" :name="orderItem.goods_name" :price="orderItem.order_price" :money="money" :orderId="orderItem.id" />
    <!-- 一键发货 -->
    <el-dialog
      title="一键发货"
      :visible.sync="dialogVisible"
      width="50%">
      <div>
        <div class="flexList">
          <div>
            <span style="color: red">*</span>选择店铺：
          </div>
          <el-select v-model="sendGoodsForm.shopId" style="margin: 0 10px 0 20px;" placeholder="请选择店铺">
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.ownerName"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" @click="jumpToStoreBind">绑定店铺</el-button>
        </div>
        <div class="flexList">
          <div>
            <span style="color: red">*</span>发件说明：
          </div>
          <div style="margin-left: 20px">
            根据授权店铺的订单号进行一键发货<br />使用此功能需要订购高级版。使用基础版可以到第三方服务里面去发货
          </div>
        </div>
        <div class="flexList">
          <div>
            温馨提示：
          </div>
          <div style="margin-left: 20px">
            有第三方订单号的订单才能一键发货
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateSendGoods()">一键发货</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="导出订单"
      :visible.sync="exportDialog"
      width="50%">
      <div>
          <span>订单格式：</span>
          <el-radio-group v-model="exportType">
            <el-radio label="1">拼多多官方</el-radio>
            <el-radio label="2">京东官方</el-radio>
            <el-radio label="3">快递助手</el-radio>
            <el-radio label="4">我打</el-radio>
            <el-radio label="5">抖音</el-radio>
            <el-radio label="6">快手官方</el-radio>
            <el-radio label="0">其他</el-radio>
          </el-radio-group>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取消</el-button>
        <el-button type="primary">
           <a :href="url+'/api/order/excel_order?id=' + exportTypeId + '&type=' + exportType" style="color: #fff" >立即导出</a>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPackageList, getUserMoney, pushThirdOrder, getUserBindShop, updateSendGoodsApi, getAddString } from 'network/api'
import { timeConversion } from 'utils/utils'
import ChildTable from './ChildTable'
import PayDialog from 'components/payDialog/PayDialog'

export default {
  components: { ChildTable, PayDialog },
  inject: ['mainReload'],
  data () {
    return {
      // activeIndex: '0',
      // orderData: {}, // 包含订单列表的对象
      showDialog: false,
      packageData: {}, // 包裹对象
      order_no: '',
      dialogShow: false,
      orderItem: {},
      money: '',
      id: null,
      showDownload: false,
      fromList: true,
      url: '',
      dialogVisible: false,
      shopList: [],
      sendGoodsForm: {
        shopId: '',
        orderId: ''
      },
      exportDialog: false,
      exportType: '0',
      exportTypeId: ''
    }
  },
  props: {
    orderData: Object, // 包含订单列表的对象
    activeIndex: String // 当前menu index
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  created () {
    this.url = this.$url
  },
  methods: {
    // 根据分页改变获取数据
    currentChange (val) {
      this.$emit('curChange', val)
    },
    // menu激活回调
    selectChange (index) {
      this.$emit('menuChange', index)
    },
    // 查看子列表
    eye (item) {
      this.order_no = item.order_no
      this.$router.push('/orderAdmin/packList?order_no=' + this.order_no)
      // this.id = item.id
      // this.showDialog = true
      // // 获取包裹列表
      // getPackageList({ order_no: this.order_no }).then(res => {
      //   this.packageData = res.data
      //   if (this.packageData.data.length !== 0) {
      //     this.showDownload = true
      //   } else {
      //     this.showDownload = false
      //   }
      // })
    },
    closeDialog () {
      this.showDialog = false
    },
    // 子列表分页更新数据更新
    updatePackageData (val) {
      getPackageList({ order_no: this.order_no, page: val }).then(res => {
        this.packageData = res.data
      })
    },
    // 取消
    remove (id) {
      this.$emit('remove', id)
    },
    exportOrder (id) {
      this.exportDialog = true
      this.exportTypeId = id
    },
    pushThird (id) {
      pushThirdOrder(id).then(res => {
        if (res.code === 1) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    sendGoods (id) {
      this.dialogVisible = true
      this.getBindShopMet()
      this.sendGoodsForm = {
        shopId: '',
        orderId: id
      }
    },
    getBindShopMet () {
      getUserBindShop().then(res => {
        if (res.data) {
          this.shopList = res.data
        }
        console.log(res, 'res')
      })
    },
    jumpToStoreBind () {
      this.$router.push('storeBind')
    },
    updateSendGoods () {
      if (!this.sendGoodsForm.shopId) {
        this.$message.error('请选择店铺')
      } else {
        updateSendGoodsApi(this.sendGoodsForm).then(res => {
          if (res.code === 1) {
            this.$message.success('操作成功')
            this.dialogVisible = false
            this.$emit('getList')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    download (data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '分析文件.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    // 支付
    payThis (data) {
      this.orderItem = data
      // 获取账户余额 并打开PayDialog
      getUserMoney().then(res => {
        this.money = res.data.usermoney
        this.dialogShow = true
      })
      console.log(this.dialogShow, '1111')
    },
    // 关闭payDialog
    toOrderList () {
      console.log('aaaa', this.dialogShow)
      this.dialogShow = false
      this.$emit('getList')
      // this.mainReload() // 更新列表
    },
    copyAddress (row) {
      getAddString({ orderId: row.id }).then(res => {
        if (res.code === 1) {
          sessionStorage.setItem('address', res.data.listString)
          this.$router.push({ path: '/orderAdmin/purchase', query: { platform: row.platform, deliverId: res.data.deliverId, storageId: res.data.storageId, goodsId: res.data.goodsId } })
          // this.$copyText(res.data).then(
          //   e => {
          //     console.log(res)
          //     this.$message.success('收货人信息已复制成功，请您粘贴继续发货！')
          //     // this.$router.push({ path: '/orderAdmin/purchase', query: { platform: row.platform } })
          //   },
          //   e => {
          //     console.log('复制失败：', e)
          //   }
          // )
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/orderTable.css">
</style>

<style>
.table .el-table tbody tr:hover > td {
  background-color: #e6f7ff;
}
.flexList{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
