<template>
  <div class="payDialog">
    <el-dialog
      title="付款：订单超过【15分钟】未支付将自动取消，请及时付款"
      width="720px"
      :visible.sync="dialogVisible"
      @close="closePayDialog"
      >
      <div v-if="showSuccess" class="content">
        <div class="name">
          商品名称：<span>{{name}}</span>
        </div>
        <div class="price">
          支付金额：<span>￥ {{Number(price).toFixed(2)}}</span>
        </div>
        <div>
          <div class="payway-btn">
            <span>支付方式：</span>
            <div class="way">
              <div class="payway active">
                <img class="yue" src="~assets/icons/yue.png">
                <img class="dagou" src="~assets/icons/dagou.png">
                <span>账户余额</span>
              </div>
            </div>
          </div>
          <div class="payway-info">
            <p style="font-size: 16px; color: red">账户余额：￥<span>{{money}}</span>元<el-button type="danger" class="payment" @click="toRecharge">去充值</el-button></p>
          </div>
          <div class="zhu">
            如出现支付提示地址错误的情况:<br/>1.通过excel导入，请在通过excel找到提示错误【行数+1】的地址删除，重新导入地址发货;<br/>2.手工填写的方式导入的请按提示【行数】删除地址;<br/>3.如前两种方式无法确定，提示语最后的【order_sn对应包裹id】，可根据【包裹id】去包裹列表搜索;
          </div>
          <el-button type="danger" :disabled="disabled" class="payment" @click="payment">{{paymentText}}</el-button>
        </div>
      </div>
      <!-- 付款成功显示 -->
      <div v-else class="content">付款成功</div>
    </el-dialog>
  </div>
</template>

<script>
import { orderPay } from 'network/api'

export default {
  data () {
    return {
      dialogVisible: false,
      pri: '',
      mon: '',
      id: '',
      showSuccess: true,
      paymentText: '立即支付',
      disabled: false,
      setMothods: true
    }
  },
  props: {
    dialogShow: { default: false },
    name: null,
    price: null,
    money: null,
    orderId: null,
    fromList: { type: Boolean, default: false }
  },
  methods: {
    closePayDialog () {
      this.dialogVisible = false
      if (this.setMothods === true) {
        this.$emit('toOrderList', 'close')
      }
      this.setMothods = false
    },
    // 前往重置
    toRecharge () {
      this.$router.push('/account/balance')
    },
    // 支付
    payment () {
      // 判断余额不足
      var p = Number.parseFloat(this.pri)
      var m = Number.parseFloat(this.mon)
      if (p > m || Number.parseFloat(this.price) > Number.parseFloat(this.money)) {
        console.log(p, m)
        this.$message({
          message: '当前账户余额不足',
          type: 'warning'
        })
        return false
      }
      // 余额支付
      const key = this.$configList[window.location.host]
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      orderPay(this.id, key).then(res => {
        this.paymentText = '支付中...'
        this.disabled = true
        const that = this
        // 支付成功
        loading.close()
        if (res.code === 1) {
          this.$message({
            message: '恭喜你，付款成功！',
            type: 'success'
          })
          this.paymentText = '立即支付'
          this.disabled = false
          // 从商城引用
          if (!this.fromList) {
            setTimeout(function () {
              that.$router.push('/orderAdmin/orderList')
            }, 1500)
          } else {
            // 从列表引用
            setTimeout(function () {
              that.dialogVisible = false
              that.closePayDialog()
            }, 1500)
          }
        } else if (res.code === -1) {
          this.$emit('toOrderList', 'noClose')
          this.setMothods = false
          // this.$message.error(res.msg)
          this.$alert(JSON.stringify(res.msg), '提示', { confirmButtonText: '确定' })
          this.paymentText = '立即支付'
          this.disabled = false
        } else {
          // 支付失败
          this.dialogVisible = false
          // this.$message.error(res.msg)
          this.$alert(JSON.stringify(res.msg), '提示', { confirmButtonText: '确定' })
          this.paymentText = '立即支付'
          this.disabled = false
        }
      })
    }
  },
  watch: {
    dialogShow (val) {
      console.log(val, 'valval')
      this.dialogVisible = val
      if (val === true) {
        this.setMothods = true
      }
    },
    price (val) {
      this.pri = val
    },
    money (val) {
      this.mon = val
    },
    orderId (val) {
      this.id = val
    }
  }
}
</script>

<style scoped src="styles/components/payDialog.css">
</style>

<style>
.payDialog .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}
.payDialog .el-button--danger {
  background-color: #f93244;
  border-color: #f93244;
}
</style>
